// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

import { useQuery } from "react-query"
import { useApi } from "../../classComponents"
import { useFilterManualContext } from "../../contexts/contexts/FilterManualInputContext"
import { useUser } from "../../contexts/contexts/UserContext"
import React, { useContext } from "react"
import { themeChartNivoContext } from "../../contexts/observer/ThemeObserver"
import Loading from "../loading"
import ErrorLoading from "../errorLoading"
import { ResponsiveLine } from "@nivo/line"
import { TooltipCustomLineChart } from "./tooltip_custom"
import Plot from "react-plotly.js"
import moment from "moment"



const convertRequestToStructure = (data: any[], reverse?: boolean): { result_classifier: any[], result_neosilos: any[] } => {
    let result_classifier: any[] = []
    let result_neosilos: any[] = []

    if (!data) return { result_classifier, result_neosilos }

    if (reverse) {
        for (let index = data.length - 1; index >= 0; index--) {
            result_neosilos.push({ y: Number(data[index]["analises"]["avaria"]).toFixed(2), x: index, sample_id: data[index]["id"] })
            if (Number(data[index]["obs"]["avaria"]) >= 0) {
                result_classifier.push({ y: Number(data[index]["obs"]["avaria"]).toFixed(2), x: index, sample_id: data[index]["id"] })
            } else {
                result_classifier.push({ y: 0, x: index, sample_id: data[index]["id"] })
            }
        }
        result_classifier = result_classifier.reverse()
        result_neosilos = result_neosilos.reverse()
    } else {
        for (let index in data) {
            result_neosilos.push({ y: Number(data[index]["analises"]["avaria"]).toFixed(2), x: index, sample_id: data[index]["id"] })
            if (Number(data[index]["obs"]["avaria"]) >= 0) {
                result_classifier.push({ y: Number(data[index]["obs"]["avaria"]).toFixed(2), x: index, sample_id: data[index]["id"] })
            } else {
                result_classifier.push({ y: 0, x: index, sample_id: data[index]["id"] })
            }

        }

    }

    return { result_classifier, result_neosilos }
}


const baseData: any[] = [
    {
        x: [],
        y: [],
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: '#e74c3c' },
        name: "Manual"
    },
    {
        x: [],
        y: [],
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: '#50bf8d' },
        name: "Equipamento"
    },
]

export default function Chart9Plotly() {
    const [filter] = useFilterManualContext()
    const [user] = useUser();
    const api = useApi(user.credential)
    const { data, isLoading, error } = useQuery(`samples-list-${JSON.stringify({ offset: 0, classifier: filter.graph2.classifier?.id, limit: filter.graph1.limit })}`, () => api.get(["graph", "get_avaria_with_manual_input"], { offset: 0, classifier: filter.graph2.classifier?.id, limit: filter.graph1.limit }))


    const [dataLocal, setDataLocal] = React.useState(baseData)

    const dataRes = React.useMemo(() => {
        if (!dataLocal || !data) return []
        let { result_classifier, result_neosilos } = convertRequestToStructure(data ?? [], true)

        let dataResAux: any[] = []

        dataResAux.push({
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: '#50bf8d' },
            name: "Neosilos",
            line: {
                shape: "spline"
            },
            hoverinfo: "y+name",
            x: result_neosilos.map(x => x.x),
            y: result_neosilos.map(x => x.y)
        })

        dataResAux.push({
            type: 'scatter',
            mode: 'lines+markers',
            hoverinfo: "y+name",
            line: {
                shape: "spline"
            },
            marker: { color: '#e74c3c' },
            name: "Classificador",
            x: result_classifier.map(x => x.x),
            y: result_classifier.map(x => x.y)
        })

        return dataResAux
    }, [filter, data])

    const themeChart = useContext(themeChartNivoContext)


    React.useEffect(() => {
        setDataLocal(dataRes)
    }, [filter, isLoading, dataRes])

    if (isLoading) return <Loading />
    if (error) return <ErrorLoading error='Chart 9 não carregou' objectError={error} />

    return (
        <Plot
            className="w-100 h-100 m-0"
            data={dataRes}

            layout={{
                autosize: true,
                paper_bgcolor: "transparent",
                plot_bgcolor: "transparent",
                yaxis: {
                    title: {
                        text: '% Avariados',
                        font: {
                            color: '#7f7f7f'
                        }
                    }
                },
                xaxis: {
                    showgrid: false,
                    title: {
                        text: 'Amostra',
                        font: {
                            color: '#7f7f7f'
                        }
                    }
                },

                font: {
                    color: themeChart.text.fill
                }
            }}

        />
    )
}


