import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { LandingPage, LoginPage } from "../screens";
import { ErrorPage403, ErrorPage404 } from "../screens/ErrorPages";
import seedClassifierRoutes from "./seed_classifier";
import AuthenticatedRoutes from "./middlewares";
import { HomePage } from "../screens";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { UserContextWrapper } from "../contexts";
import WebsocketContextWrapper from "../contexts/contexts/WebSocketContext";
import { ReportPage } from "../screens/SeedClassifierPages";
import webHookRoutes from "./webhook";

const Router = createBrowserRouter([
    {
        element: <><ToastContainer /><UserContextWrapper><Outlet /></UserContextWrapper></>,
        children: [
            {
                path: "/",
                index: true,
                element: <LandingPage />,
            }, {
                path: "login",
                element: <LoginPage />
            },
            {
                element: <AuthenticatedRoutes />,
                errorElement: <Navigate to="/login/" replace={true} />,
                children: [
                    {
                        element: <WebsocketContextWrapper><MasterLayout /></WebsocketContextWrapper>,
                        children: [
                            {
                                path: "/",
                                element: <HomePage />
                            },
                            {
                                path: "homepage",
                                element: <HomePage />
                            },
                            {
                                path: "seed_classifier",
                                children: seedClassifierRoutes
                            }, {
                                path: "webhook",
                                children: webHookRoutes
                            },
                        ]
                    }

                ]
            },
            {
                path: "seed_classifier/report/:id",
                element: <ReportPage />
            },
            {
                path: "404",
                element: <ErrorPage404 />
            }, {
                path: "403",
                element: <ErrorPage403 />
            },
            {
                path: "*",
                element: <ErrorPage404 />
            }
        ]
    }
])

export default Router;