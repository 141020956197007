import { Autocomplete, TextField } from "@mui/material"
import { useFilterManualContext } from "../../contexts/contexts/FilterManualInputContext"
import { useQuery } from "react-query"
import { useUser } from "../../contexts/contexts/UserContext"
import { useApi } from "../../classComponents"
import Loading from "../loading"
import ErrorLoading from "../errorLoading"

export default () => {
    const [filter, setFilter] = useFilterManualContext()
    const [user] = useUser()
    const api = useApi(user.credential)
    const { data, isLoading,error } = useQuery(`users-list`, async (): Promise<userType[]> => api.get("users"))
    if (isLoading) return <Loading />
    if(error) return <ErrorLoading error="Erro ao carregar lista de usuários" objectError={error}/>
    return (
        <div className="row w-100 ps-3 mt-5">
            <h5 className="text-center center">Filtros</h5>
            <hr className="mx-auto" style={{ width: "80%" }} />
            <div className="row mx-auto">
                <div className="row mb-3">
                    <Autocomplete
                        disablePortal
                        options={ data ? data.map(elem => ({ label: elem.first_name+" "+elem.last_name[0], id: elem.id})) : []}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(ex, value) => { setFilter({ type: "SET_3", payload: { classifier: value } }) }}
                        value={filter.graph1.classifier}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} size="small" label="Classificador" />}
                    />
                </div>
                <div className="row mb-3">
                    <Autocomplete
                        disablePortal
                        options={[1, 2, 3, 4, 5].map((num) => ({ label: num * 10 + " últimas", value: num * 10 }))}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(ev, obj) => { setFilter({ type: "SET_3", payload: { limit: obj?.value  } }) }}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} size="small" label="Amostras" />}
                    />
                </div>
            </div>
        </div>
    )
}