import WebhookPage from "../../screens/WebhookPages"

const webHookRoutes:RouterFormat[] = [
    {
        path: "",
        icon: "technology-4",
        name: "Webhooks",
        element: <WebhookPage />,
    }
]

export default webHookRoutes