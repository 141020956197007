import moment from 'moment'
import React, { useMemo } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import Card from '../../../components/card_dashboard'
import Chart2 from '../../../components/charts/chart2'
import Chart3 from '../../../components/charts/chart3'
import Chart4 from '../../../components/charts/chart4'
import Chart5 from '../../../components/charts/chart5'
import Chart6 from '../../../components/charts/chart6'
import { AvariasList } from '../../../components/lists/AvariasList'
import { ListMachines } from '../../../components/lists/ListMachines'
import { ListRegionals } from '../../../components/lists/ListRegionals'
import { useFilterContext } from '../../../contexts/contexts/FilterContext'
import { WebsocketContext } from '../../../contexts/contexts/WebSocketContext'
import Chart7 from '../../../components/charts/chart7'

const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
const queryClientDashboard = new QueryClient()

export default function DashboardPage() {
  const [filter] = useFilterContext()
  
  const socket = React.useContext(WebsocketContext)
  React.useEffect(() => {

    socket.on("update_component", (data: any) => {
      console.log("[Socket client DASHBOARD] update_component", data)
    })

    return () => {
      socket.off("update_component");
    }
  }, [])

  const labelChar2 = useMemo(() => `Avarias do mês (${moment(filter.created_at_before).subtract(29,"days").month() === moment(filter.created_at_before).month() ? "" : months[moment(filter.created_at_before).subtract(29,"days").month()]+"/" }${months[moment(filter.created_at_before).month()]})`, [filter.created_at_after,filter.created_at_before])
  return (
    <QueryClientProvider client={queryClientDashboard}>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="row mb-3">
            <div className="col-12 col-md-4">
              <Card title='Classificações registradas' height='h-100' body={<Chart5 />} />
            </div>
            {/* <div className="col-12 col-md-4">
              <Card title='Classificações com tratados' height='h-100' body={<Chart7 />} />
            </div> */}
            <div className="col-12 col-md-8">
              <Card title='Tipos de Grãos' height='h-100' body={<Chart6 />} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Card title={labelChar2} height='h-275px' body={<Chart2 />} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3 pb-3">
          <Card title='Atividade das Maquinas' height='h-500px' body={<Chart3 />} />
        </div>
      </div>
      <div className="row mb-3 pb-3">
        <div className="col-12">
          <Card title="Densidade de Classificações" height='h-300px' body={<Chart4 />} />
        </div>
      </div>
      <div className="row mb-3 pb-3">
        <div className="col-12 col-md-4">
          <Card title="Classificações por Regional" height='h-400px' body={<ListRegionals />} />
        </div>
        <div className="col-12 col-md-4">
          <Card title="Médias de Avarias" height='h-400px' body={<AvariasList />} />
        </div>
        <div className="col-12 col-md-4">
          <Card title="Maquinas por Atividade" height='h-400px' body={<ListMachines />} />
        </div>
      </div>
    </QueryClientProvider>
  )
}
