import React from 'react'
import { useLocation } from 'react-router-dom'
import { reInitMenu } from '../../_metronic/helpers'
import { Content } from '../../_metronic/layout/components/content'
import { FooterWrapper } from '../../_metronic/layout/components/footer'
import { HeaderWrapper } from '../../_metronic/layout/components/header'
import { ScrollTop } from '../../_metronic/layout/components/scroll-top'
import { PageDataProvider } from '../../_metronic/layout/core'
import QRCode from 'react-qr-code'


function HeaderLanding(props: any) {
    return (

        <div className="row p-10 mb-10 shadow-sm" style={{ backgroundColor: "var(--primary-color)" }}  {...props}>
            <img src="/media/logos/neosilos-horizontal-02.svg" style={{ maxWidth: "800px" }} className='img-fluid mx-auto' alt="Tela Da Neosilos" />
        </div>

    )
}

function VisioLanding(props: any) {
    return (
        <div {...props}>
            <div className="container-sm mt-5">
                <div className="row justify-content-around landing-neosilos" >
                    <div className="col-md-6 d-flex justify-content-center mb-3">
                        <img className="mx-auto" src={"/media/machine/ns-visio_alpha_no_shade_trim.png"} width={"90%"} height={"auto"} alt="" />
                    </div>
                    <div className="col-md-6 text-center mb-3">
                        <div className="row mb-3 justify-content-center">
                            <h2 style={{ fontSize: "1.5rem", fontWeight: "700", letterSpacing: "0.3em", lineHeight: "1.5em"}}>CONHEÇA A VISIO</h2>
                            <p style={{ fontSize: "4.5em", fontWeight: "600", letterSpacing: "-0.04em", lineHeight: "1.1em"}}>CLASSIFICADORA DE GRÃOS</p>
                            <p className="mb-6 mt-6" style={{ textAlign: "justify" }}>Equipamento para a classificação de grãos por imagem utilizando algoritmos de inteligência artificial, resultados com alta acuracidade, facilidade de uso, agilidade no tempo de ciclo e integração com plataforma digital de visualização de dados.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="row text-center mb-5 mt-5"> <p style={{ fontSize: "1.5rem", fontWeight: "700", letterSpacing: "0.3em", lineHeight: "1.5em"}}>Culturas:</p> </div>
                            <div className="col-4">
                                <div className="card card-custom">
                                    <div className="card-body p-3">
                                        <img src={"/media/machine/soy-bean.svg"} alt="Imagem Soja" width={"50px"} />
                                        <p>Soja</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card card-custom">
                                    <div className="card-body p-3">
                                        <img src={"/media/machine/corn.svg"} alt="Imagem Milho" width={"50px"} />
                                        <p>Milho</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function LineLanding(props: any) {
    return (
        <div {...props}>
            <div className="row justify-content-around" >
                <div className="col-12 col-md-4 text-center mb-3">
                    <div className="row mb-3 justify-content-center">
                        <p className="ls-4 fs-6">Sistema de medição volumétrica para armazens
                            graneleiros - LINE</p>
                        <p style={{ fontSize: "300%", fontWeight: "900" }}>CONHE&#199;A A </p>
                        <p style={{ fontSize: "300%", fontWeight: "900" }}> LINE</p>
                        <p style={{ maxWidth: "300px" }}>Sistema automato para medição volumétrica de forma recorrente e com integração
                            com plataforma digital de visualização de dados.</p>
                    </div>
                    <div className="row justify-content-center">
                        {[{ title: "Precisão", description: "Tenha uma medição precisa mesmo com geometrias complexas em larga escala.", img: "/media/machine/benefict1.png" },
                        {
                            title: "Acertividade", description: "Com medições precisas recorrentes é possivel ter uma melhor gestão do estoque fisico.", img: "/media/machine/benefict2.png"
                        }].map(item => {
                            return (
                                <div className="col-6">
                                    <div className="card card-custom" style={{ minHeight: "200px" }}>
                                        <div className="card-body p-3">
                                            <img className="mb-5" src={item.img} alt="Imagem Soja" width={"50px"} />
                                            <h6>{item.title}</h6>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-center mb-3">
                    <img className="mx-auto" src={"https://imgs.search.brave.com/j8z0X0EWnCO94vcZzNAJchcJmnnOq97_A-JUWif8g2Q/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9nZWVr/ZmxhcmUuY29tL3dw/LWNvbnRlbnQvdXBs/b2Fkcy8yMDIzLzAz/L2ltZy1wbGFjZWhv/bGRlci5wbmc"} width={"400px"} height={"auto"} alt="" />
                </div>

            </div>
        </div>
    )
}

function BenefitsLanding(props: any) {
    return (
        <div {...props}>
            <div className="container-sm mt-5">
                <div className="row landing-neosilos" >
                    {
                        [
                            { title: "Facilidade de uso", content: "A Visio tem uma interface simples, com poucos toques sua amostra já está classificada.", img: "/media/machine/benefict1.png" },
                            {
                                title: "Padronização", content: "Segurança e confiabilidade para o seu negócio com classificações padronizadas.", img: "/media/machine/benefict2.png"
                            },
                            {
                                title: "Baixo Tempo de Ciclo", content: <>Tempo de ciclo de apenas <strong>40 segundos</strong>.</>, img: "/media/machine/benefict3.svg"
                            },
                            {
                                title: "Acompanhamento", content: "Acompanhe em tempo real as classificações realizadas atraves de nosso dashboard.", img: "/media/machine/benefict4.svg"
                            }
                        ].map((item, index) => {
                            return (

                                <div key={index} className="col-6 col-md-3 mb-3">
                                    <div className="card card-custom " style={{ minHeight: "200px" }}>
                                        <div className="card-header justify-content-center px-3 ">
                                            <img className="align-self-center" src={item.img} alt="Imagem Soja" width={"50px"} height={"50px"} />
                                        </div>
                                        <div className="card-body p-3 text-center">
                                            <h6>{item.title}</h6>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function QuemSomosLanding(props: any) {
    return (
        <div {...props}>
            <div className="row justify-content-around" >
                <div className="col-12 col-md-4 d-flex justify-content-center mb-3">
                    <img className="mx-auto" src={"https://imgs.search.brave.com/j8z0X0EWnCO94vcZzNAJchcJmnnOq97_A-JUWif8g2Q/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9nZWVr/ZmxhcmUuY29tL3dw/LWNvbnRlbnQvdXBs/b2Fkcy8yMDIzLzAz/L2ltZy1wbGFjZWhv/bGRlci5wbmc"} width={"400px"} height={"auto"} alt="" />
                </div>
                <div className="col-12 col-md-4 text-center mb-3">
                    <div className="row mb-3 h-100 justify-content-center align-self-center">
                        <p style={{ fontSize: "300%", fontWeight: "900" }}>QUEM SOMOS</p>
                        <p style={{ maxWidth: "300px" }}>Nossa missão é sofisticar o processo de inspeção de qualidade e contribuir para um
                            mercado mais transparente e justo tanto para quem compra quanto para quem vende.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

function ParceirosLanding(props: any) {
    return (
        <div {...props}>

            <div className="container-sm mt-4 mb-5 landing-neosilos" style={{ textAlign: "center", padding: "2.5rem 0" }}>
                <p style={{ fontSize: "3.5em", fontWeight: "600" }}>Parceiros:</p>
                <div className="row mt-5 justify-content-center d-flex flex-wrap align-items-center">
                    {
                        ["/media/machine/cnpq.png", "/media/machine/finep.png", "/media/machine/fundacao-araucaria.png", "/media/machine/abdi.svg"].map(
                            item => <div className="col-3"><img  className='my-auto' src={item} alt={item} style={{ maxWidth: "90%", maxHeight: "9.375rem", margin: "0 1.25rem", objectFit: "contain" }}/></div>
                        )
                    }
                </div>
                <div className="row mt-5 justify-content-center d-flex flex-wrap align-items-center">
                    {
                        ["/media/machine/ufsc.png", "/media/machine/utfpr.png", "/media/machine/cocamar.png","/media/machine/lar.png"].map(
                            item => <div className="col-3"><img className='my-auto' src={item} alt={item} style={{ maxWidth: "90%", maxHeight: "9.375rem", margin: "0 1.25rem", objectFit: "contain" }} /></div>
                        )
                    }
                </div>
                <div className="row mt-5 justify-content-center d-flex flex-wrap align-items-center">
                    <div className="col-3 offset-3"><img className='my-auto' src={"/media/machine/jbs.png"} alt={"/media/machine/jbs.png"} style={{ maxWidth: "90%", maxHeight: "9.375rem", margin: "0 1.25rem", objectFit: "contain" }} /></div>
                    <div className="col-3"><img className='my-auto' src={"/media/machine/frisia.png"} alt={"/media/machine/frisia.png"} style={{ maxWidth: "90%", maxHeight: "9.375rem", margin: "0 1.25rem", objectFit: "contain" }} /></div>
                </div>

                {/* <div className="row justify-content-center mb-10">
                    <p style={{ fontSize: "200%", fontWeight: "300" }}>Para mais informações entre em contato usando o QR code:</p>
                    <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                            value={"https://www.linkedin.com/in/iancavalc/"}
                            viewBox={`0 0 256 256`}
                        />
                </div> */}

            </div>

        </div>
    )
}


export default function LandingPage() {
    const location = useLocation()
    React.useEffect(() => {
        reInitMenu()
    }, [location.key])

    return (
        <>
            <PageDataProvider>
                <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
                    <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
                        <HeaderWrapper />
                        <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                                <div className='d-flex flex-column flex-column-fluid'>
                                    
                                    <div className='row'>
                                        <HeaderLanding />
                                        
                                        <VisioLanding className="mb-10" />
                                        <BenefitsLanding className="mb-10" />
                                        {/* <LineLanding className="mb-10" /> */}
                                        {/* <QuemSomosLanding className="mb-10" /> */}
                                        <ParceirosLanding />
                                    
                                    </div>
                                    
                                </div>
                                <FooterWrapper />
                            </div>
                        </div>
                    </div>
                </div>

                {/* begin:: Drawers */}
                {/* <ActivityDrawer /> */}
                {/* <RightToolbar /> */}
                {/* <DrawerMessenger /> */}
                {/* end:: Drawers */}

                {/* begin:: Modals */}
                {/* <InviteUsers /> */}
                {/* <UpgradePlan /> */}
                {/* end:: Modals */}
                <ScrollTop />
            </PageDataProvider>

        </>
    )
}
