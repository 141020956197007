/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'


type Props = {
  text: string;
  img?: string;
  desc?: string;
}

const SimpleInfoCell: FC<Props> = ({ text, img, desc }) => {

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {img ? (
            <div className='symbol-label'>
              <img src={img} alt={"Simple Image"} className='w-100' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${text[0]}`,
                `text-${text[0]}`
              )}
            >
              {text[0] || "?"}
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {text || "Nenhum"}
        </a>
        <span>{desc || ""}</span>
      </div>
    </div>
  )
}

export { SimpleInfoCell }
