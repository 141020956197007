import moment from "moment";
import React, { Dispatch, useContext, useMemo } from "react";
import FilterManualInputReducer from "../reducers/FIlterManualInputReducer";


const defaultData = {
    graph1:{
        min: -100,
        max: 100,
        limit: 25,
    },
    graph2: {
        
    },
    graph3:{
        
    }
   
}


export const FilterContext = React.createContext<[IManualInput, Dispatch<any>]>([defaultData, () => { }])

const FilterManualInputContextWrapper = ({ children }: any) => {
    const [filter, dispatch] = React.useReducer(FilterManualInputReducer, defaultData);
    const filterMemo = useMemo(() => filter, [filter])
    return (
        <FilterContext.Provider value={[filterMemo, dispatch]} >
            {children}
        </FilterContext.Provider>
    )
}

export function useFilterManualContext() {
    return useContext(FilterContext)
}


export default FilterManualInputContextWrapper