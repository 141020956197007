import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import Card from '../../../components/card_dashboard'
import Chart8 from '../../../components/charts/chart8'
import Chart9 from '../../../components/charts/chart9'
import FilterForm_1 from '../../../components/filterForms/FilterForm_1'
import FilterForm_2 from '../../../components/filterForms/FilterForm_2'
import ListAvariasManualInput from '../../../components/lists/ListAvariasManualInput'
import FilterManualInputContextWrapper from '../../../contexts/contexts/FilterManualInputContext'
import { WebsocketContext } from '../../../contexts/contexts/WebSocketContext'
import Chart9Plotly from '../../../components/charts/chart9-plotly'
import Chart8Plotly from '../../../components/charts/chart8-plotly'
import ListAvariasHeadManualInput from '../../../components/lists/ListAvariasHeadManualInput'
import FilterForm_3 from '../../../components/filterForms/FilterForm_3'

const queryClientDashboard = new QueryClient()




export default function DashboardPage() {

  const socket = React.useContext(WebsocketContext)
  React.useEffect(() => {

    socket.on("update_component", (data: any) => {
      console.log("[Socket client DASHBOARD] update_component", data)
    })

    return () => {
      socket.off("update_component");
    }
  }, [])

  return (
    <QueryClientProvider client={queryClientDashboard}>
      <FilterManualInputContextWrapper>
      <div className="row mb-3">
          <div className="col-3">
            <Card height='h-100 w-100' body={<FilterForm_3 />} />
          </div>
          <div className="col-9">
            <Card className="text-center" title='Estatísticas Básicas' body={<ListAvariasHeadManualInput />} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-3">
            <Card height='h-100 w-100' body={<FilterForm_1 />} />
          </div>
          <div className="col-9">
            <Card title='Diferença - Equipamento x Manual' body={<Chart8Plotly />} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-3">
            <Card height='h-100' body={<FilterForm_2 />} />
          </div>
          <div className="col-9">
            <Card title='Comparação - Equipamento x Manual' height='h-400px' body={<Chart9Plotly />} />
          </div>
        </div>
        
        <div className="row mb-3">
          <Card title='Atividade das Maquinas' height='h-500px' body={
            <ListAvariasManualInput />
          } />
        </div>
      </FilterManualInputContextWrapper>
    </QueryClientProvider>
  )
}
