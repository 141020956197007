/* eslint-disable react/jsx-no-target-blank */
import { UserClass } from '../../../../../classComponents'
import { RequestUrl } from '../../../../../classComponents/api/index.class';
import DivWithoutStyle from '../../../../../components/DivWithoutStyle';
import { useUser } from '../../../../../contexts/contexts/UserContext'
import webHookRoutes from "../../../../../routers/webhook"
import seedClassifierRoutes from "../../../../../routers/seed_classifier"
import { SidebarMenuItem } from './SidebarMenuItem'


const SidebarMenuMain = () => {
  // const intl = useIntl()

  const [user] = useUser();
  const userFunctions = new UserClass(user.profile)


  return (
    <>
      {userFunctions.isStaff() ? <SidebarMenuItem to={RequestUrl + "/admin/"} title='Admin' icon='profile-circle' fontIcon='bi-person' /> : null}


      <SidebarMenuItem
        to='/homepage/'
        icon='element-11'
        title={'Página Inicial'}
        fontIcon='bi-app-indicator'
      />

      {user.services?.map((item: servicesType, key: number) => {

        return (
          <DivWithoutStyle key={key}>
            {
              item.ativo && item.uuid.includes("2093a3cf-bc98-43d6-b26f-c18bde2e21b4") && user.enterprise?.servicos.includes(item.uuid) ?
                // menu items for seed classifier
                <>
                  <div className='menu-item' key={item.uuid}>
                    <div className='menu-content pt-8 pb-2'>
                      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{item.nome}</span>
                    </div>
                  </div>

                  {seedClassifierRoutes.map((item, index) => {
                    if (user.profile !== null && (item.permission ? userFunctions.hasPermission(item.permission) : true) && (item.show !== undefined ? item.show : true))
                      return <SidebarMenuItem key={index}
                        to={`/seed_classifier/${item.path}`}
                        icon={item.icon}
                        title={item.name}
                        fontIcon='ki-duotune'
                      />
                    return null
                  })}
                </> : null}

            {/* anotehr product: Volumentria */}
            {item.ativo && item.uuid.includes("7e8dc25d-2ebc-4d56-8d7c-4c6d5e048fd7") && user.enterprise?.servicos.includes("7e8dc25d-2ebc-4d56-8d7c-4c6d5e048fd7") ? <>
              <div className='menu-item' key={item.uuid}>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{item.nome}</span>
                </div>
              </div>
            </>
              : null}
          </DivWithoutStyle>)

      })}

      {webHookRoutes.map((item, index) => {
        if (user.profile !== null && (item.permission ? userFunctions.hasPermission(item.permission) : true) && (item.show !== undefined ? item.show : true))
          return <SidebarMenuItem
            key={index }
            to={`/webhook/${item.path}`}
            icon={item.icon}
            title={item.name}
            fontIcon='ki-duotune'
          />
        return null
      })}


      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }

