
export default function FilterManualInputReducer(filter: IManualInput, action: any) {
    switch (action.type) {
        case "SET_1":
            return {...filter, ["graph1"]: {...filter.graph1, ...action.payload}}
        case "SET_2":
            return {...filter,["graph2"]: {...filter.graph2, ...action.payload}}
            case "SET_3":
            return {...filter,["graph3"]: {...filter.graph3, ...action.payload}}
        default:
            return filter
    }
}