import { useContext } from 'react'
import { listContext } from '../../../../../contexts/contexts/ListContext'
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import React from 'react'

const UsersListHeader = () => {
  const {selected} = useListView()
  const listProps = useContext(listContext)
  const [localState, setLocalState] =  React.useState(false)

  React.useEffect(() => {
    setLocalState(listProps.search===true || listProps.search === undefined)
  },[listContext])

  if (!localState) return null
  return (
    
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
