import { KTCard, KTIcon } from "../../_metronic/helpers";
import { UsersListHeader } from "./users-list/components/header/UsersListHeader";

import { UsersTable } from "./users-list/table/UsersTable";

import { PropsWithChildren, useEffect, useLayoutEffect, useState } from "react";
import { Column } from "react-table";
import Loading from '../../components/loading';
import { BaseModal } from '../../components/modals/BaseModal';
import ModalContextWrapper, { useModalContext } from "../../contexts/contexts/ModalContext";
import { PaginationContextWrapper, usePagination } from "../../contexts/contexts/PaginationContext";
import { BaseModalCells } from "../list_table/users-list/table/columns/baseModalCells/SampleActionCell";
import { TextCell } from "./users-list/table/columns/usersCells/TextCell";
import { UserCustomHeader } from "./users-list/table/columns/usersCells/UserCustomHeader";
import ListContextUpdatedWrapper, { useListContext } from "../../contexts/contexts/ListContext_updated";



type Props = {
    data: any,
    pagination?: boolean,
    columns?: ReadonlyArray<Column<any>>,
    form?: (props: any) => JSX.Element,
    widgets?: ("ADD_BUTTON" | "SEARCH")[]
}



const exampleColumns: ReadonlyArray<Column<any>> = [

    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Exemplo1' className='min-w-125px' />,
        id: 'campo1',
        Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].name} />
    }, {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Exemplo2' className='min-w-125px' />,
        id: 'campo2',
        Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].name} />
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Exemplo3' className='min-w-125px' />,
        id: 'campo3',
        Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].name} />
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Exemplo5' className='min-w-125px' />,
        id: 'campo5',
        Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].name} />
    }, {
        Header: (props: PropsWithChildren<any>) => (
            <UserCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({ ...props }) => <BaseModalCells item={props.data[props.row.index] as {}} />,
    },

]

function FormSample() {
    return (
        <div className="d-flex justify-content-center">
            <h1>Form não registrado, por favor retire das Columns</h1>
            <img src="https://imgs.search.brave.com/UkM_Y9qq6txYq_l5s_5U4rwn4NB9b5SvlxHzq9mozDk/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pMC53/cC5jb20vZGlhbmF1/cmJhbi5jb20vd3At/Y29udGVudC91cGxv/YWRzLzIwMTcvMTAv/Y2F0LXR5cGluZy5n/aWY_cmVzaXplPTQw/MCw0MDAmc3NsPTE.gif" alt="" />
        </div>
    )
}



const ListTableWithContextWrapper = (props: Props) => {
    return (
        <ModalContextWrapper defaultValue={{ form: props.form !== undefined ? props.form : FormSample, show: false, title: "Amostra Registrada", data: undefined, type: 'GET' }}>
            <ListContextUpdatedWrapper>
                <PaginationContextWrapper>
                    <ListTableWithContext {...props} />
                </PaginationContextWrapper>
            </ListContextUpdatedWrapper>
        </ModalContextWrapper>
    )
}

function ListTableWithContext(props: any) {
    const [getModalContext, setModalContext] = useModalContext()
    const [pagination] = usePagination()
    const [listProps, setListProps] = useListContext()

    useEffect(() => {
        setListProps({
            type: "SET", payload: {
                results: props.pagination === true ? (props.data as IPagination<any>) : props.data, columns: props.columns ? props.columns : exampleColumns, widgets: props.widgets
            }
        })
    }, [pagination])

    return (
        <>

            <KTCard>
                {listProps.widgets ? <UsersListHeader /> : null}
                <UsersTable />
            </KTCard>
            <BaseModal title={getModalContext?.title} show={getModalContext.show} handleClose={() => setModalContext({ type: "SHOW", payload: false })}>
                {getModalContext?.form ? <getModalContext.form /> : null}
            </BaseModal>
        </>
    )
}

export default ListTableWithContextWrapper