/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormEvent } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { ApiClass } from '../../classComponents'
import { useModalContext } from '../../contexts/contexts/ModalContext'
import { usePagination } from '../../contexts/contexts/PaginationContext'
import { useUser } from '../../contexts/contexts/UserContext'
import CharFieldForm from '../fields/Charfield'


const EditWebhookForm = () => {
    const [getModalContext, setModalContext] = useModalContext()
    const [user] = useUser()
    const api = new ApiClass<any>(user.credential)
    const [pagination] = usePagination()
    const { refetch } = useQuery(`webhook-list`, async (): Promise<any[] | any | IPagination<any>> => api.get("webhook", { ...pagination }))

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (getModalContext.type === 'POST') {
            api.create("webhook", {
                nome: e.currentTarget.nome.value,
                url: e.currentTarget.url.value,
            }).then((response) => {
                toast.success("[Webhook] criado com sucesso!")
            }).catch((error) => {
                toast.error(error.message)
            })
        } else {
            api.partial_update("webhook", getModalContext.data.id, {
                nome: e.currentTarget.nome.value,
                url: e.currentTarget.url.value,
            }).then((response) => {
                toast.success("[Webhook] atualizado com sucesso!")
            }).catch((error) => {
                toast.error(error.message)
            })
        }
        setTimeout(refetch, 1000)
        setModalContext({ type: "SHOW", payload: false })
    }


    return (
        <form onSubmit={onSubmit}>
            {getModalContext.type !== "POST" && <div className='row mb-10'>
                <div className="col">
                    <CharFieldForm name='id' title='ID' type="text" required={false} editable={false} defaultValue={getModalContext.data} />
                </div>
                <div className="col">
                    <CharFieldForm name='created_at' title='criado em' type="text" required={false} editable={false} defaultValue={getModalContext.data} />
                </div>
            </div>}

            <div className='row mb-10'>
                <div className="col">
                    <CharFieldForm name='nome' title='Nome' type="text" required={true} editable={true} defaultValue={getModalContext.data} />
                </div>
                <div className="col">
                    <CharFieldForm name='url' title='URL' type="text" required={true} editable={true} defaultValue={getModalContext.data} />
                </div>
            </div>
            <div className="row my-3 justify-content-end">
                <div className="col-2">
                    <input type="submit" className='btn btn-lg btn-primary w-100' value={getModalContext.data ? "Salvar" : "Criar"} />
                </div>
            </div>
        </form>
    )
}
export { EditWebhookForm }

