import { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from '../table/columns/CustomHeaderColumn'
import { CustomRow } from '../table/columns/CustomRow'
import { User } from '../core/_models'
import { UsersListLoading } from '../components/loading/UsersListLoading'
import { KTCardBody } from '../../../../_metronic/helpers'
import { ListPaginationOffsetLimit } from '../components/pagination/ListPaginationOffsetLimit'
import { useListContext } from '../../../../contexts/contexts/ListContext_updated'

const UsersTable = () => {
  // dados da tabela
  const [values] = useListContext()
  const isLoading = false

  
  const data = useMemo(() => values.results || values, [values])
  // Columns data
  const columns = useMemo(() => values.columns, [values])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data
  })

  return (
    <KTCardBody className='py-4' >
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum Resultado Encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {
        values.count !== undefined ? <ListPaginationOffsetLimit /> : null
      }

      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export { UsersTable }
