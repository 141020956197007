import { Point } from '@nivo/line'
import moment from 'moment'
import React from 'react'
import { tooltipLineChartProps, tooltipProps } from '.'

export default function TooltipCustom({ color, value, day }: tooltipProps) {
    return (
        <span style={{ background: 'white', color: 'black', padding: "2px", border: '1px groove #000' }}>
            <span style={{ color: color, fontSize: '1.2rem' }}>■</span><span className='ps-1' style={{ fontSize: '1rem' }}>: {day ? moment(day).format('DD/MM') : null} {value ? value : null}</span></span>
    )
}


export function TooltipCustomLineChart({ point }: tooltipLineChartProps) {
    return (
        <span style={{ background: 'white', color: 'black', padding: "2px", border: '1px groove #000' }}>
            <span style={{ color: point.serieColor, fontSize: '1.2rem' }}>■</span><span className='ps-1' style={{ fontSize: '1rem' }}>: {point.data.yFormatted}</span>
        </span>
    )
}
