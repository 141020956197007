import { ThemeProvider, createTheme } from "@mui/material";
import { createContext, useEffect, useMemo, useState } from "react";
import { ThemeModeComponent } from "../../_metronic/assets/ts/layout";
import { useThemeMode } from "../../_metronic/partials";

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'
export const themeChartNivoContext = createContext({} as any)

export const colors = {
    dark: "#000",
    light: "#fff"
}

export const currentColorTextTheme = () => colors[systemMode]

const themeDefault = {

}

export default function ThemeObserverWrapper({ children }: any) {
    const { mode } = useThemeMode()


    const calculatedMode = useMemo(() =>
        mode === 'system' ? systemMode : mode
        , [mode])


    const themeDefault = {
        labels: {
            text: {
                fontSize: 14,
                fill: calculatedMode == 'dark' ? colors.light : colors.dark,
                outlineWidth: 0,
                outlineColor: "transparent",
            },
        },
        text: {
            fill: calculatedMode == 'dark' ? colors.light : colors.dark,
        },
        axis: {
            ticks: {
                text: {
                    fill: calculatedMode == 'dark' ? colors.light : colors.dark,
                    fontSize: ".6em",
                }
            },
            legend: {
                text: {
                    fill: calculatedMode == 'dark' ? colors.light : colors.dark,
                }
            }
        },
        legends: {
            title: {
                text: {
                    fill: calculatedMode == 'dark' ? colors.light : colors.dark,
                }
            }

        },
        annotations: {
            text: {
                fill: calculatedMode == 'dark' ? colors.light : colors.dark,
            }
        }
    }

    const [themeChartNivo, setThemeChartNivo] = useState(themeDefault)

    useEffect(() => {
        setThemeChartNivo(themeDefault)
    }, [mode])

    const Theme = createTheme({
        palette: {
            mode: calculatedMode,
        },
    });

    return (
        <ThemeProvider theme={Theme}>
            <themeChartNivoContext.Provider value={themeChartNivo}>
                {/* <CssBaseline /> */}
                {children}
            </themeChartNivoContext.Provider>
        </ThemeProvider>
    )
}


