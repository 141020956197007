// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


import React, { useContext } from 'react'
import Plot from 'react-plotly.js'
import { useQuery } from 'react-query'
import { useApi } from '../../classComponents'
import { useFilterManualContext } from '../../contexts/contexts/FilterManualInputContext'
import { useUser } from '../../contexts/contexts/UserContext'
import { themeChartNivoContext } from '../../contexts/observer/ThemeObserver'
import ErrorLoading from '../errorLoading'
import Loading from '../loading'


const baseData =
[{
    type: 'scatter',
    mode: 'lines+markers',
    marker: {
        color: "green"
    },
    name: "Manual",
    line: {
        shape: "spline"
    },
    hoverinfo: "y+name",
    x: [],
    y: []
},{
    type: 'scatter',
    mode: 'lines+markers',
    marker: {
        color: "green"
    },
    name: "Equipamento",
    line: {
        shape: "spline"
    },
    hoverinfo: "y+name",
    x: [],
    y: []
}]
type RequestStructured =
    { x: number, y: number, sample_id?: string }[]

const convertRequestToStructure = (data: any[]): RequestStructured => {
    let result: RequestStructured = []
    if (data === undefined || data.length === 0) return []

    for (let index in data) {
        if (Number(data[index]["obs"]["avaria"]) >= 0) {
            result.push({
                y: Number(Number(data[index]["analises"]["avaria"] - data[index]["obs"]["avaria"]).toFixed(2)),
                x: Number(index),
                sample_id: data[index]["id"]
            })
        } else result.push({
            y: Number(Number(data[index]["analises"]["avaria"]).toFixed(2)),
            x: Number(index),
            sample_id: data[index]["id"]
        })

    }
    return result
}

export default function Chart8Plotly() {
    const [filterManual] = useFilterManualContext()
    const [user] = useUser();
    const api = useApi(user.credential)
    const { data, isLoading, error } = useQuery(`samples-list-${JSON.stringify({ offset: 0, classifier: filterManual.graph1.classifier?.id, limit: filterManual.graph1.limit })}`, () => api.get(["graph", "get_avaria_with_manual_input"], { offset: 0, classifier: filterManual.graph1.classifier?.id, limit: filterManual.graph1.limit }))


    const [dataLocal, setDataLocal] = React.useState<any>(baseData)

    const dataRes = React.useMemo(():any => {
        if (!dataLocal) return []
        let dataResAux = convertRequestToStructure(data || [])

        dataResAux = dataResAux.filter((value: any) => value.y <= filterManual.graph1.max && value.y >= filterManual.graph1.min)



        if (filterManual.graph1.sort?.value === "cres") {
            dataResAux = dataResAux.sort((a, b) => a.y - b.y)
            dataResAux.forEach((elem, index) => elem.x = index)
        }
        if (filterManual.graph1.sort?.value === "decr") {
            dataResAux = dataResAux.sort((a, b) => b.y - a.y)
            dataResAux.forEach((elem, index) => elem.x = index)
        }

        // separe green, red, line
        const green = {
            type: 'scatter',
            mode: 'markers',
            marker: {
                color: "green"
            },
            name: "Equipamento",
            line: {
                shape: "spline"
            },
            hoverinfo: "y+name",
            x: dataResAux.map(x => x.y >= 0 ? x.x : x.x),
            y: dataResAux.map(x => x.y >= 0 ? x.y : null)
        }

        const red = {
            type: 'scatter',
            mode: 'markers',
            marker: {
                color: "red"
            },
            name: filterManual.graph1.classifier ? filterManual.graph1.classifier.label : "Manual",
            line: {
                shape: "spline"
            },
            hoverinfo: "y+name",
            x: dataResAux.map(x => x.y < 0 ? x.x : x.x),
            y: dataResAux.map(x => x.y < 0 ? x.y : null)
        }

        const line = {
            type: 'scatter',
            mode: 'line',
            marker: {
                color: "rgba(160,160,160,.3)"
            },
            name: "",
            line: {
                shape: "spline"
            },
            hoverinfo: "y+name",
            x: dataResAux.map(x =>  x.x),
            y: dataResAux.map(x => x.y )
        }
        return { green, red, line  }
    }, [filterManual, data])

    const themeChart = useContext(themeChartNivoContext)


    React.useEffect(() => {
        const {green, red,line} = dataRes
        setDataLocal([green, red,line])
    }, [filterManual, isLoading, dataRes])

    if (isLoading) return <Loading />
    if (error) return <ErrorLoading error='Chart 8 não carregou' objectError={error} />
    return (

        <Plot
            className="w-100 h-100 m-0"
            data={dataLocal}
            layout={{
                autosize: true,
                paper_bgcolor: "transparent",
                plot_bgcolor: "transparent",
                showlegend: false,
                yaxis: {
                    title: {
                        text: '% Diferença',
                        font: {
                            color: '#7f7f7f'
                        },
                    },
                    showgrid: false,
                    zeroline: true,
                },
                xaxis: {
                    showgrid: false,
                    title: {
                        text: 'ID Amostra',
                        font: {
                            color: '#7f7f7f'
                        }
                    }
                },
                font: {
                    color: themeChart.text.fill
                }
            }}


        />
    )
}


