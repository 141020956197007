/* eslint-disable array-callback-return */
import moment from "moment"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { usePDF } from "react-to-pdf"
import { useApi } from "../../../classComponents"
import ErrorLoading from "../../../components/errorLoading"
import Loading from "../../../components/loading"
import { ErrorPage404 } from "../../ErrorPages"
import QRCode from "react-qr-code";

const queryClient = new QueryClient()

type RowProps = {
    col1: string;
    col2?: string;
    className?: string;
}
function Row({ col1, col2, className }: RowProps) {

    return (
        <div className={"row " + (className ? className : "")} >
            <div className="col-4 fw-bold align-self-center" style={{fontSize: "4rem",color: "#000"}} >
                {col1}
            </div>
            <div className="col-8 align-self-center text-end" style={{fontSize: "4rem",color: "#000"}}>
                {col2}
            </div>
        </div>
    )
}

function Title({ col1, className }: RowProps) {

    return (
        <div style={{fontSize: "4.5rem", color: "#000"}} className={"row w-100 fw-bold py-5 mx-auto" + (className ? className : "")} >
            <p className="mx-auto w-auto">{col1}</p>
        </div>
    )
}

export function ReportPage() {
    const params = useParams()

    const api = useApi()
    const { data, isLoading, error } = useQuery(`relatorio-${params.id}`, async () => api.retrieve("amostras", params.id ? params.id : "0"))
    const { toPDF, targetRef } = usePDF({ filename: `relatorio_${params.id}.pdf`, page: { format: "A4"} })

    if (params.id === undefined) return <ErrorPage404 />
    if (isLoading) return <Loading />
    if (error) return <ErrorLoading objectError={error} error={"Falha ao carregar este Relatório"} />

    return (
        <div>
            <button className="btn btn-success mb-3" onClick={() => toPDF()}>Baixar em PDF</button>
            <div ref={targetRef}>
                <div className="bg-white text-primary px-4" >
                    <div className="row justify-content-center py-5">
                        <img src="/media/logos/neosilos-horizontal-bw.svg" style={{ width: "80%" }} />
                    </div>
                    <Title col1={"RESUMO DE CLASSIFICAÇÃO ID-" + params.id} />
                    <Row className="mb-3" col1="Maquina" col2={data.maquina_id} />
                    <Row className="mb-3" col1="Data de Registro" col2={moment(data.created_at).format("DD/MM/YYYY HH:mm")} />

                    <Title col1={"Produto"} />
                    <Row className="mb-3" col1="Tipo de Grão" col2={data.produto ? data.produto.name : "Indefinido"} />
                    {
                        data.fornecedor ? <Row className="mb-3" col1="Fornecedor" col2={data.fornecedor} /> : null
                    }

                    <Title col1={"Dados da Classificação"} />
                    <Title col1={"Geral"} className="fs-2" />
                    {
                        Object.keys(data.analises as {}).map((item: any) => {
                            if (["sadio", "avaria"].includes(item))
                                return (
                                    <Row className="mb-3" col1={item} col2={data.analises[item].toFixed(3) + " %"} />
                                )
                        })
                    }
                    <Title col1={"Defeitos Graves"} className="fs-2" />
                    {
                        Object.keys(data.analises as {}).map((item: any) => {
                            if (["ardido", "mofado", "queimado"].includes(item))
                                return (
                                    <Row className="mb-3" col1={item} col2={data.analises[item].toFixed(3) + " %"} />
                                )
                        })
                    }
                    <Title col1={"Defeitos Leves"} className="fs-2" />
                    {
                        Object.keys(data.analises as {}).map((item: any) => {
                            if (["fermentado", "danificado", "germinado", "imaturo", "chocho", "esverdeado", "amassado", "partido", "quebrado","carunchado","gessado","chocho_imaturo"].includes(item))
                                return (
                                    <Row className="mb-3" col1={item} col2={data.analises[item].toFixed(3) + " %"} />
                                )
                        })
                    }
                    <Title col1={"Tratado"} className="fs-2" />
                    <Row className="mb-3" col1="tratado" col2={data.tratado >= .33 ? "Sim" : "Não"} />
                    <div className="p-5" style={{ height: "auto", margin: "0 auto", maxWidth: 500, width: "100%" }}>
                        <QRCode
                            size={500}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={window.location.href}
                            viewBox={`0 0 256 256`}
                        />
                    </div>

                    <div className="row pb-3">
                        <div className="col-4"></div>
                        <div className="col-8">
                            <div className="row">
                                <div className="col-7 text-end" style={{fontSize: "3rem",color: "#000"}}>Documento Gerado em: </div>
                                <div className="col-5" style={{fontSize: "3rem",color: "#000"}}>{moment().format("DD/MM/YYYY HH:mm")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default function ReportPageWrapper() {
    return (
        <QueryClientProvider client={queryClient}>
            <ReportPage />
        </QueryClientProvider>
    )

}