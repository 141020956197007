import { Column } from "react-table";
import { ListTable } from "..";
import { ListComponentWrapper } from "../../contexts";
import { PropsWithChildren } from "react";
import { UserCustomHeader } from "../list_table/users-list/table/columns/usersCells/UserCustomHeader";
import { TextCell } from "../list_table/users-list/table/columns/usersCells/TextCell";
import moment from "moment";
import { useQuery } from "react-query";
import { useApi } from "../../classComponents";
import { useUser } from "../../contexts/contexts/UserContext";
import { useFilterManualContext } from "../../contexts/contexts/FilterManualInputContext";
import Loading from "../loading";
import ErrorLoading from "../errorLoading";
import React from "react";

const samplesColumns: ReadonlyArray<Column<sampleType>> = [
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Data' className='min-w-125px' />,
        id: 'Data',
        Cell: ({ ...props }) => <TextCell text={moment(props.data[props.row.index].created_at).format("DD/MM/YYYY HH:mm")} />,
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='ID Classificação' className='min-w-125px' />,
        id: 'ID Classificação',
        Cell: ({ ...props }) => <TextCell text={String(Number(props.data[props.row.index].obs.id_externo))} />
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Classificador' className='min-w-125px' />,
        id: 'Classificador',
        Cell: ({ ...props }) => <TextCell text={(props.data[props.row.index].classificador?.username || "Nenhum")} />
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='% Avaria Equipamento' className='min-w-125px' />,
        id: '% avariadas',
        Cell: ({ ...props }) => <TextCell text={Number(props.data[props.row.index].analises.avaria).toFixed(2)} />
    }, {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='% Avaria Manual' className='min-w-125px' />,
        id: '% avarias',
        Cell: ({ ...props }) => <TextCell text={Number(props.data[props.row.index].obs.avaria).toFixed(2)} />
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='% Diferença' className='min-w-125px' />,
        id: 'diferença',
        Cell: ({ ...props }) => <TextCell text={(Number(props.data[props.row.index].analises.avaria) - Number(props.data[props.row.index].obs.avaria)).toFixed(2)} />
    },


]

export default function ListAvariasManualInput() {
    const [user] = useUser();
    const api = useApi(user.credential)
    const [filterManual] = useFilterManualContext()

    const { data, isLoading, error ,refetch} = useQuery(`samples-list-${JSON.stringify({ offset: 0, classifier: filterManual.graph1.classifier?.id, limit: filterManual.graph1.limit })}`, () => api.get(["graph", "get_avaria_with_manual_input"], { offset: 0, classifier: filterManual.graph1.classifier?.id, limit: filterManual.graph1.limit }))
    React.useEffect(() => {
        refetch()
    }, [filterManual, data])
    if (isLoading) return <Loading />
    if (error) return <ErrorLoading error="Lista não carregou corretamente" objectError={error} />

    return (
        <ListComponentWrapper content={({ ...({ results: data }), columns: samplesColumns,search:false })}>
            <ListTable />
        </ListComponentWrapper>
    )
}