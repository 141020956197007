export default function ListContextReducer(list: any, action:any){
    switch (action.type) {
        case "SET":
            console.log({...list, ...action.payload})
            return {...list, ...action.payload}
        case "SET_WIDGETS": 
            return {...list, widgets: action.payload}
        default:
            return list
    }
}