import { Autocomplete, TextField } from "@mui/material"
import { useFilterManualContext } from "../../contexts/contexts/FilterManualInputContext"
import { useQuery } from "react-query"
import { useUser } from "../../contexts/contexts/UserContext"
import { useApi } from "../../classComponents"
import Loading from "../loading"
import ErrorLoading from "../errorLoading"

export default () => {
    const [filter, setFilter] = useFilterManualContext()
    const [user] = useUser()
    const api = useApi(user.credential)
    const { data, isLoading,error } = useQuery(`users-list`, async (): Promise<userType[]> => api.get("users"))
    if (isLoading) return <Loading />
    if(error) return <ErrorLoading error="Erro ao carregar lista de usuários" objectError={error}/>
    return (
        <div className="row w-100 ps-3 mt-5">
            <h5 className="text-center center">Filtros</h5>
            <hr className="mx-auto" style={{ width: "80%" }} />
            <div className="row mx-auto">
                <div className="row w-100 align-items-center  mb-3">
                    <div className="col-5">
                        <Autocomplete
                            disablePortal
                            options={[0, -8, -12, -20, -25, -30, -100]}
                            defaultValue={-100}
                            disableClearable
                            getOptionLabel={(option) => option + "" || ""}
                            onChange={(ev, value) => { setFilter({ type: "SET_1", payload: { min: value } }); }}
                            value={filter.graph1.min}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} size="small" label="min" />}
                        />
                    </div>
                    <div className="col-2 text0-center"><p>-</p></div>
                    <div className="col-5">
                        <Autocomplete
                            disablePortal
                            options={[0, 8, 12, 20, 25, 30, 100]}
                            defaultValue={100}
                            getOptionLabel={(option) => option + "" || ""}
                            disableClearable
                            // isOptionEqualToValue={(option, value) => option.value === value.value}
                            onChange={(ev, value) => { setFilter({ type: "SET_1", payload: { max: value } }); }}
                            value={filter.graph1.max}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} size="small" label="max" />}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <Autocomplete
                        disablePortal
                        options={ data ? data.map(elem => ({ label: elem.first_name+" "+elem.last_name[0], id: elem.id})) : []}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(ex, value) => { setFilter({ type: "SET_1", payload: { classifier: value } }) }}
                        value={filter.graph1.classifier}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} size="small" label="Classificador" />}
                    />
                </div>
                <div className="row mb-3">
                    <Autocomplete
                        disablePortal
                        options={[1, 2, 3, 4, 5].map((num) => ({ label: num * 10 + " últimas", value: num * 10 }))}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(ev, obj) => { setFilter({ type: "SET_1", payload: { limit: obj?.value || 30} });  setFilter({ type: "SET_3", payload: { limit: obj?.value } }) }}
                        value={({label: filter.graph1.limit+ " últimas", value: filter.graph1.limit})}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} size="small" label="Amostras" />}
                    />
                </div>
                <div className="row mb-3">
                    <Autocomplete
                        disablePortal
                        options={[{ label: "Crescente", value: "cres" }, { label: "Decrescente", value: "decr" }, { label: "Nenhum", value: "any" }]}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(ev, obj) => setFilter({ type: "SET_1", payload: { sort: obj } })}
                        disableClearable
                        value={filter.graph1.sort}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} size="small" label="Ordenação" />}
                    />
                </div>
            </div>
        </div>
    )
}