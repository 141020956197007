import { KTIcon } from '../../../../../_metronic/helpers'
import { useListContext } from '../../../../../contexts/contexts/ListContext_updated'
import { useModalContext } from '../../../../../contexts/contexts/ModalContext'
import { useListView } from '../../core/ListViewProvider'
import { UsersListToolbar } from './UserListToolbar'
import { UsersListGrouping } from './UsersListGrouping'
import { UsersListSearchComponent } from './UsersListSearchComponent'

const UsersListHeader = () => {
  const { selected } = useListView()
  const [listProps,] = useListContext()
  const [, setModalContext] = useModalContext()

  if (listProps.widgets === undefined) return null

  return (
    <div className='card-header border-0 pt-6'>
      <div className='d-flex justify-content-between justify-content-end w-100'>
        {
          listProps.widgets.indexOf("SEARCH") > -1 ?
            <UsersListSearchComponent />
            : null
        }

        {
          listProps.widgets.indexOf("ADD_BUTTON") > -1 ?
            <button type='button' className='btn btn-primary my-auto' onClick={() => setModalContext({ type: "SET", payload: { show: true, data: undefined, title: "Adicionar", type: 'POST' } })} >
              <KTIcon iconName='plus' className='fs-2' />
              Adicionar
            </button> : null
        }
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { UsersListHeader }

