import { DashboardPage, MachinePage, ManualInputPage, SamplePage, UserPage } from "../../screens/SeedClassifierPages";

const seedClassifierRoutes:RouterFormat[] = [
    {
        path: "",
        icon:'element-plus',
        name: "dashboard",
        show: false,
        element: <DashboardPage />,
    },
    {
        path: "dashboard",
        icon:'element-plus',
        name: "Dashboard",
        element: <DashboardPage />,
    },
    {
        path: "machines",
        name: "Maquinas",
        icon: "cube-2",
        permission: "gestor",
        element: <MachinePage />,
    },
    {
        path: "samples",
        name: "Amostras",
        icon: "simcard",
        permission: "gestor",
        element: <SamplePage />,
    },
    {
        path: "users",
        name: "Usuários",
        icon: "people",
        permission: "gestor",
        element: <UserPage />,
    },
    {
        path: "manual_input",
        name: "Dados Manuais",
        icon: "eye",
        permission: "gestor",
        element: <ManualInputPage />,
    }
]

export default seedClassifierRoutes