import React from 'react';

type CardProps = {
    body?: React.JSX.Element;
    title?: string;
    description?: string;
    footer?: React.JSX.Element;
    height?: string;
    className?: string;
}

export default function Card(CardProps: CardProps) {
    return (
        <div style={{maxWidth:"100%"}} className={`card card-custom  ${CardProps.height ? CardProps.height : 'h-350px'} ${CardProps.className ? CardProps.className: "" }` }>
            {
                CardProps.title ? <div className="card-header">
                    <h3 className="card-title w-100 lh-sm">{CardProps?.title ? CardProps.title : ""}</h3>
                    {/* <div className="card-toolbar">
                    <button type="button" className="btn btn-sm btn-light">
                        Action
                    </button>
                </div> */}
                </div> : null
            }

            {
                CardProps?.body ? <div className={`card-body card-scroll p-0`} >
                    <small>{CardProps?.description}</small>
                    {CardProps?.body}
                </div> : null
            }
            {
                CardProps?.footer ?
                    <div className="card-footer">
                        Footer
                    </div> : null
            }
        </div>
    )
}
