import { ResponsivePie } from '@nivo/pie'
import { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { ApiClass } from '../../classComponents'
import { useFilterContext } from '../../contexts/contexts/FilterContext'
import { useUser } from '../../contexts/contexts/UserContext'
import { themeChartNivoContext } from '../../contexts/observer/ThemeObserver'
import ErrorLoading from '../errorLoading'
import Loading from '../loading'
import TooltipCustom from './tooltip_custom'

export default function Chart6() {
    // const [data, setData] = React.useState<any[]>([])
    const [user] = useUser()
    const [filter] = useFilterContext()
    const themeChart = useContext(themeChartNivoContext)

    const api = new ApiClass<any>(user.credential)
    const { data, isLoading, error } = useQuery(`getCountGraficos-${JSON.stringify(filter)}`, () => api.get(["graph", "get_count"], { ...filter }))


    const formatData = (data: any): any[] => {
        let formated_data: any[] = []
        if (data === null || data === undefined) return formated_data
        Object.keys(data).map(key => {
            if (key == "todos" || key == "null" || key == "tratados") return
            formated_data.push(
                {
                    id: key,
                    label: key,
                    value: data[key],
                }
            )
        })

        return formated_data
    }

    const formatDataF = useMemo(() => {
        return formatData(data)
    }, [data])

    if (isLoading) return <Loading />
    if (error) return <ErrorLoading error="Erro ao carregar o Chart6" objectError={error} />






    return (
        <>
            {

                formatDataF.filter((a) => a.value !== 0).length < 1 ?
                    <div className='text-center my-10'>
                        <h1 style={{ fontSize: "4rem" }}>0</h1>
                        <p>Amostras encontradas</p>
                    </div>
                    : <ResponsivePie
                        data={formatDataF}
                        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                        innerRadius={.5}
                        padAngle={0.5}
                        cornerRadius={2}
                        activeOuterRadiusOffset={5}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        tooltip={data => {
                            return (
                                <TooltipCustom {...data.datum} />
                            )
                        }}
                        arcLinkLabelsSkipAngle={2}
                        arcLinkLabelsTextColor={themeChart.text.fill}
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsDiagonalLength={3}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={8}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}

                        fill={[
                            {
                                match: {
                                    id: 'ruby'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'c'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'go'
                                },
                                id: 'dots'
                            }
                        ]}

                    />
            }
        </>

    )
}
