// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

import { useQuery } from "react-query"
import { useApi } from "../../classComponents"
import { useFilterManualContext } from "../../contexts/contexts/FilterManualInputContext"
import { useUser } from "../../contexts/contexts/UserContext"
import ErrorLoading from "../errorLoading"
import Loading from "../loading"



export default function ListAvariasHeadManualInput() {
    const [filter] = useFilterManualContext()
    const [user] = useUser();
    const api = useApi(user.credential)
    const { data, isLoading, error } = useQuery(`samples-list-table-${JSON.stringify({ offset: 0, classifier: filter.graph3.classifier?.id, limit: filter.graph3.limit })}`, () => api.get(["graph", "get_avaria_with_manual_input_table"], { offset: 0, classifier: filter.graph3.classifier?.id, limit: filter.graph3.limit }))

    if (isLoading) return <Loading />
    if (error) return <ErrorLoading error='Tabela Manual Input' objectError={error} />

    return (
        <div className="table-responsive-lg">
            <table className="table table-bordered w-auto mx-auto my-5 fw-bold" style={{transform: "scale(1.1)"}}>
             
                <tbody>
                    <tr>
                        <th scope="row" >Total de Classificações</th>
                        <td className="w-200px">{data.count}</td>
                    </tr>
                    <tr>
                        <th scope="row">Diferença Média</th>
                        <td>{data.media.toFixed(3)}</td>
                    </tr>
                    <tr>
                        <th scope="row">Diferença Mediana</th>
                        <td>{data.median.toFixed(3)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}


