import { ResponsiveTimeRange } from '@nivo/calendar'
import moment from 'moment'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { ApiClass } from '../../classComponents'
import { useFilterContext } from '../../contexts/contexts/FilterContext'
import { useUser } from '../../contexts/contexts/UserContext'
import { themeChartNivoContext } from '../../contexts/observer/ThemeObserver'
import { is_darkmode } from '../../utils/darkmode'
import ErrorLoading from '../errorLoading'
import Loading from '../loading'
import TooltipCustom from './tooltip_custom'
import { firstDayOfYear, lastDayOfYear } from './utils'



const monthsNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

export default function Chart4() {
    const [user] = useUser();
    const [filter] = useFilterContext()
    const api = new ApiClass<any>(user.credential)
    const themeChart = useContext(themeChartNivoContext)
    const makedFilter = { ...filter, created_at_before: lastDayOfYear(filter.created_at_before), created_at_after: firstDayOfYear(filter.created_at_after) }
    const { data, isLoading, error } = useQuery(`getSadiosByDayGraficos-${JSON.stringify(makedFilter)}`, () => api.get(["graph", "get_sadios_by_day"], makedFilter))

    if (isLoading) return <Loading />
    if (error) return <ErrorLoading error="Erro ao carregar o Chart4" objectError={error} />

    const formatData = (data: any[]): any[] => {
        return data.map((x: any) => ({
            "value": x.count,
            "day": x.date
        }))
    }



    return (
        <ResponsiveTimeRange
            data={formatData(data)}
            from={moment().format((filter.year ? filter.year : "YYYY") + "-01-02")}
            to={moment().format((filter.year ? filter.year : "YYYY") + "-12-31")}
            emptyColor="#eeeeee"
            colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
            margin={{ top: 60, right: 20, bottom: 40, left: 20 }}
            // yearSpacing={40}
            dayBorderWidth={2}
            // monthSpacing={3}
            // monthBorderColor="transparent
            theme={themeChart}
            dayBorderColor={is_darkmode() ? '#000' : '#fff'}
            monthLegend={(year, month, date) => monthsNames[date.getMonth()]}
            // weekdayLegend={}
            dayRadius={5}
            tooltip={data => {
                return <TooltipCustom {...data} />
            }}
            legends={
                [{
                    anchor: 'bottom',
                    direction: 'row',
                    translateY: -60,
                    itemCount: 5,
                    itemWidth: 42,
                    itemTextColor: themeChart.labels.text.fill ? themeChart.labels.text.fill : "#fff",
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left',
                }
                ]}
        />
    )
}
