import { PropsWithChildren, useEffect } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Column } from "react-table";
import { PageLink, PageTitle } from "../../_metronic/layout/core";
import { useApi } from "../../classComponents";
import ErrorLoading from "../../components/errorLoading";
import { EditWebhookForm } from "../../components/forms/EditWebhookForm";
import { SimpleInfoCell } from "../../components/list_table/users-list/table/columns/baseModalCells/SimpleInfoCell";
import { TextCell } from "../../components/list_table/users-list/table/columns/usersCells/TextCell";
import { UserCustomHeader } from "../../components/list_table/users-list/table/columns/usersCells/UserCustomHeader";
import { WebhookActionCell } from "../../components/list_table/users-list/table/columns/webhookCells/WebhookIActionCell";
import ListTableWithContext from "../../components/list_table_with_context";
import Loading from "../../components/loading";
import { useUser } from "../../contexts/contexts/UserContext";

const samplesBreadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/homepage/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const webhookColumns: ReadonlyArray<Column<any>> = [

    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Identificação' className='min-w-125px' />,
        id: 'name',
        Cell: ({ ...props }) => <SimpleInfoCell text={props.data[props.row.index].nome} desc={props.data[props.row.index].url} />,
    }, {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
        id: 'webhooname',
        Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].nome} />
    },
    {
        Header: (props: PropsWithChildren<any>) => <UserCustomHeader tableProps={props} title='Url' className='min-w-125px' />,
        id: 'webhookurl',
        Cell: ({ ...props }) => <TextCell text={props.data[props.row.index].url} />
    },
    {
        Header: (props: PropsWithChildren<any>) => (
            <UserCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({ ...props }) => <WebhookActionCell item={props.data[props.row.index] as {}} />,
    },


]


const queryClient = new QueryClient()


export default function WebhookPageWrapper() {
    return (
        <QueryClientProvider client={queryClient}>
            <WebhookPage />
        </QueryClientProvider>
    )
}




function WebhookPage() {
    const [user] = useUser()
    const api = useApi(user.credential)
    const { data, isLoading, error } = useQuery(`webhook-list`, async (): Promise<any[]> => api.get("webhook"))
    useEffect(()=>{
        
    },[data])
    if (error) return <ErrorLoading error={"Não foi possível buscar os Webhooks"} objectError={error} />
    if (isLoading) return < Loading />

   

    return (
        <>
            <PageTitle breadcrumbs={samplesBreadcrumbs}>Webhook</PageTitle>
            <ListTableWithContext data={data} form={EditWebhookForm} columns={webhookColumns} widgets={["ADD_BUTTON"]} />
        </>
    )
}