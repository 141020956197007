/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { useModalContext } from '../../../../../../contexts/contexts/ModalContext'
import { useApi } from '../../../../../../classComponents'
import { useUser } from '../../../../../../contexts/contexts/UserContext'
import { toast } from 'react-toastify'

type Props = {
    item: any;
}

const WebhookActionCell: FC<Props> = ({ item }) => {
    const [, setModalContext] = useModalContext()
    const [ user] = useUser()
    const api = useApi(user.credential)

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])


    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                Ações
                <KTIcon iconName='down' className='fs-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-kt-menu='true'
            >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={() => { setModalContext({ type: "SET", payload: { show: true, data: item, title: "Visualizar Webhook", type: 'PATCH' } }) }}>
                        Editar
                    </a>
                </div>
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={() => { 
                            api.create(["webhook", "send"], {
                                id: item.id
                            })
                            .then( res => toast.success("[Webhook] Teste Enviado"))
                            .catch(ex => toast.error("[Webhook] Teste Não Foi Recebido") )
                     }}>
                        Enviar teste
                    </a>
                </div>


                {/* end::Menu item */}

                {/* begin::Menu item */}
                {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => { }}
          >
            Delete
          </a>
        </div> */}
                {/* end::Menu item */}
            </div>
            {/* end::Menu */}
        </>
    )
}

export { WebhookActionCell }
