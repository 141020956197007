import { Dispatch, createContext, useContext, useReducer } from "react";
import ListContextReducer from "../reducers/ListContextReducer";

interface listContextProps {
    columns: any,
    results: any[],
    count?: number,
    next?: string | null,
    previous?: string | null,
    widgets?: string[],
    search?: boolean
}

type ListContextType = {
    content?: listContextProps;
    children: any;
}

const defaultValues = {
    columns: [],
    results: [],
    count: 0,
    next: null,
    previous: null,
    widgets: [],
    search:false,
}

export const listContext = createContext<[listContextProps, Dispatch<any>]>([defaultValues, () => {}])

const ListContextUpdatedWrapper = ({ children }: ListContextType) => {
    const [ value, dispatch] = useReducer(ListContextReducer, defaultValues)
    return (
        <listContext.Provider value={[value, dispatch]}>
                {children}
        </listContext.Provider>
    )
}

const useListContext = () => {
    return useContext(listContext)
}


export {useListContext}
export default ListContextUpdatedWrapper